import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import store from '@/store/index'
import { addAssignments, deleteAssignments, editAssignments, getAssignmentsList } from '@/api/api.assignments'
import JlinkUtils from '@/common/global/JlinkUtils'

@Module({ name: 'Assignments', dynamic: true, namespaced: true, store })
class Assignments extends VuexModule {
  assignments: AssignmentsBaseShowVo[] = []

  @Mutation
  SET_ASSIGNMENTS_LIST (data: ReqGetAssignmentsList['Response']) {
    this.assignments = data
  }

  @Mutation
  APPEND_ASSIGNMENTS (assignments: AssignmentsBaseShowVo) {
    if (!this.assignments.map(i => i.assignmentsId).includes(assignments.assignmentsId)) {
      this.assignments.unshift(assignments)
    }
  }

  @Mutation
  CHANGE_ASSIGNMENTS (data: AssignmentsBaseVo |
      { assignmentsId: string, assignmentsType: number, assignmentsStatus: number, onlineDeviceNum:number, state: 0 | 1 | 2 | 3 | 4 } |
      { assignmentsId: string, onlineDeviceNum: number }) {
    const target = this.assignments.findBy('assignmentsId', data.assignmentsId)
    JlinkUtils.obj.dataSet(data, target)
  }

  @Mutation
  DELETE_ASSIGNMENTS (assignmentsId: string) {
    this.assignments.removeIf('assignmentsId', assignmentsId)
  }

  @Action({ rawError: true })
  async getList (data: ReqGetAssignmentsList['Request']): Promise<void> {
    const res = await getAssignmentsList(data)
    this.SET_ASSIGNMENTS_LIST(res)
  }

  @Action({ rawError: true })
  async addAssignments (data: ReqAddAssignments['Request']): Promise<void> {
    const res = await addAssignments(data)
    this.APPEND_ASSIGNMENTS(res)
  }

  @Action({ rawError: true })
  async editAssignments (data: ReqEditAssignments['Request']): Promise<void> {
    const res = await editAssignments(data)
    this.CHANGE_ASSIGNMENTS(res)
  }

  @Action({ rawError: true })
  async deleteAssignments (data: ReqDeleteAssignments['Request']): Promise<void> {
    await deleteAssignments(data)
    this.DELETE_ASSIGNMENTS(data.assignmentsId)
  }
}

export const AssignmentsModule = getModule(Assignments)
