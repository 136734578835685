import Layout from '@/app/layout/WebLayout.vue'
import { RouteRecordRaw } from 'vue-router'

import {
  WEB_MAIN,
  WEB_GROUP,
  WEB_LIBRARY, WEB_WAYLINE_EDITOR, WEB_FLIGHT_CONTROL, WEB_EMERGENCY, WEB_DEFAULT
} from '@/router/route.model.web'
import {COMMON_LIVESTREAM_SHARE, COMMON_LOCATION_SHARE} from '@/router/route.model.base'

/* 网站路由 */
const webRoutes: RouteRecordRaw[] = [

  {
    path: WEB_DEFAULT.path,
    name: WEB_DEFAULT.name,
    component: Layout,
    meta: { title: '网页入口' },
    redirect: WEB_MAIN.fullPath,
    children: [{
      path: WEB_MAIN.path,
      name: WEB_MAIN.name,
      component: () => import('@/app/views/pages/assignments/index.vue'),
      meta: { title: '任务' }
    },
    {
      path: WEB_LIBRARY.path,
      name: WEB_LIBRARY.name,
      component: () => import('@/app/views/pages/library/LibraryGroup.vue'),
      meta: { title: '数据' }
    }, {
      path: WEB_GROUP.path,
      name: WEB_GROUP.name,
      component: () => import('@/app/views/pages/group/GroupView.vue'),
      meta: { title: '机队' }
    }]
  },
  {
    path: WEB_EMERGENCY.path,
    name: WEB_EMERGENCY.name,
    component: () => import('@/app/views/pages/emergencyTasks/index.vue'),
    meta: { title: '指点飞行', hidden: true }
  },
  {
    path: WEB_WAYLINE_EDITOR.path,
    name: WEB_WAYLINE_EDITOR.name,
    component: () => import('@/app/views/pages/library/wayline/editor/WaylineEditor.vue'),
    meta: { title: '航线编辑', hidden: true }
  },
  {
    path: WEB_FLIGHT_CONTROL.path,
    name: WEB_FLIGHT_CONTROL.name,
    component: () => import('@/app/views/pages/flightControl3/FlightControl.vue'),
    meta: { title: '飞行控制', hidden: true }
  },
  {
    path: COMMON_LIVESTREAM_SHARE.path,
    name: COMMON_LIVESTREAM_SHARE.name,
    props (route) {
      return {
        secretKey: route.query.secretKey
      }
    },
    component: () => import('@/app/views/pages/shares/LivestreamShare.vue'),
    meta: { title: '直播分享', hidden: true, keepAlive: false }

  },
  {
    path: COMMON_LOCATION_SHARE.path,
    name: COMMON_LOCATION_SHARE.name,
    props (route) {
      return {
        location: route.query.location
      }
    },
    component: () => import('@/app/views/pages/shares/LocationShare.vue'),
    meta: { title: '位置分享', hidden: true, keepAlive: false }

  }
]

export default webRoutes
