import { getModule, Module, VuexModule, Action } from 'vuex-module-decorators'
import store from '@/store/index'
import { getAssignmentsBase } from '@/api/api.assignments'
import { AssignmentsModule } from '@/store/Assignments'
import { AssignmentsOpenModule } from '@/store/AssignmentsOpen'
import JlinkUi from '@/common/global/JlinkUi'

@Module({ name: 'CompanyNotifyMessage', dynamic: true, namespaced: true, store })
class CompanyNotifyMessage extends VuexModule {
  @Action({ rawError: true })
  async HANDLE_MESSAGE (message: NotifyMessageBase<any>) {
    if (message.bizCode !== 0) {
      return
    }
    // 普通消息
    switch (message.messageType) {
      // 任务状态变化
      case 0:
        const data = message.data as { assignmentsId: string, onlineDeviceNum:number, assignmentsType: number, assignmentsStatus: number, state: 0 | 1 | 2 | 3 | 4 }
        switch (data.state) {
          case 0:
            const res = await getAssignmentsBase({ assignmentsId: data.assignmentsId })
            res.onlineDeviceNum = data.onlineDeviceNum
            AssignmentsModule.APPEND_ASSIGNMENTS(res)
            return
          case 1:
            const res1 = await getAssignmentsBase({ assignmentsId: data.assignmentsId })
            AssignmentsModule.CHANGE_ASSIGNMENTS(res1)
            if ((data.assignmentsId === AssignmentsOpenModule.assignmentsId)) {
              AssignmentsOpenModule.CHANGE_ASSIGNMENTS(res1)
            }
            break
          case 2:
            AssignmentsModule.CHANGE_ASSIGNMENTS(data)
            if ((data.assignmentsId === AssignmentsOpenModule.assignmentsId)) {
              AssignmentsOpenModule.CHANGE_ASSIGNMENTS(data)
            }
            break
          case 3:
            AssignmentsModule.DELETE_ASSIGNMENTS(data.assignmentsId)
            if ((data.assignmentsId === AssignmentsOpenModule.assignmentsId)) {
              console.log('message1', data)
              AssignmentsOpenModule.CHANGE_ASSIGNMENTS(data)
              const res = await JlinkUi.baseConfirm('该任务已结束,是不是现在退出')
              console.log('message2', res)
              if (res.action === 'confirm') {
                await AssignmentsOpenModule.leave()
              }
            }
            break
          case 4:
            AssignmentsModule.DELETE_ASSIGNMENTS(data.assignmentsId)
            if (data.assignmentsId === AssignmentsOpenModule.assignmentsId) {
              const res = await JlinkUi.baseConfirm('该任务已删除,是不是现在退出')
              if (res.action === 'confirm') { AssignmentsOpenModule.SET_ASSIGNMENTS_DETAIL() }
            }
            break
        }
        break
      // 任务设备上下线状态变化
      case 1:
        const data1 = message.data as { assignmentsId: string, onlineDeviceNum: number }
        AssignmentsModule.CHANGE_ASSIGNMENTS(data1)
        break
    }
  }
}

export const CompanyNotifyMessageModule = getModule(CompanyNotifyMessage)
