import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["DeepImage"], {
    "class": "deep-file-image",
    fit: "cover",
    src: $props.src,
    lazy: $props.lazy,
    "oss-resize": ""
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        name: "file-download-line",
        "class": "icon"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["src", "lazy"]);
}