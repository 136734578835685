import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store/index'
import { addDroneParts, deleteDroneParts, getDronePartsListByPage, modifyDroneParts } from '@/api/api.drone.parts'
import JlinkUtils from '@/common/global/JlinkUtils'

interface IParamsState {
  deviceList: DronePartsBaseVo[];
  totalCount: number;
}

let requestData: ReqGetDronePartsListByPage['Request'] | undefined

@Module({ name: 'dronePartsList', dynamic: true, namespaced: true, store })
class DronePartsList extends VuexModule implements IParamsState {
  deviceList: DronePartsBaseVo[] = []
  totalCount = 0

  @Mutation
  SET_DEVICE_LIST (data: ReqGetDronePartsListByPage['Response']) {
    this.totalCount = data.totalCount
    this.deviceList = data.data
  }

  @Mutation
  APPEND_DEVICE (device: DronePartsBaseVo) {
    if (requestData && requestData.page === 0) {
      const len = this.deviceList.unshift(device)
      if (len > requestData.size) {
        this.deviceList.pop()
      }
    }
    this.totalCount += 1
  }

  @Mutation
  CHANGE_DEVICE (data: any) {
    const target = this.deviceList.findBy('dronePartsId', data.dronePartsId)
    JlinkUtils.obj.dataSet(data, target)
  }

  @Action({ rawError: true })
  async getList (data: ReqGetDronePartsListByPage['Request']): Promise<void> {
    if (data.page < 0) {
      console.debug('page must >=0')
      return Promise.resolve()
    }
    requestData = data
    const res = await getDronePartsListByPage(data)
    this.SET_DEVICE_LIST(res)
  }

  @Action({ rawError: true })
  async addDevice (data: ReqAddDroneParts['Request']): Promise<void> {
    const res = await addDroneParts(data)
    this.APPEND_DEVICE(res)
  }

  @Action({ rawError: true })
  async modifyDevice (data: ReqDronePartsUpdate['Request']): Promise<void> {
    const res = await modifyDroneParts(data)
    this.CHANGE_DEVICE(res)
  }

  @Action({ rawError: true })
  async deleteDevice (data: ReqDeleteDroneParts['Request']): Promise<void> {
    await deleteDroneParts(data)
    if (requestData) {
      await this.getList(requestData)
    }
  }
}

export const DronePartsListModule = getModule(DronePartsList)
