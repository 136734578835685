import store from '@/store/index'
import {
  addMember,
  changeRoleAsAdmin,
  deleteMember,
  getMemberList,
  modifyUser
} from '@/api/api.user'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import JlinkUtils from '@/common/global/JlinkUtils'
import {USER_STATUS} from "@/common/enum/JLINK_ENUMS";

interface IParamsState {
  memberList: LoginUserVo[];
  totalCount: number;
}

let requestData: ReqGetMemberList['Request'] | undefined

@Module({ name: 'Members', dynamic: true, namespaced: true, store })
class Members extends VuexModule implements IParamsState {
  memberList: LoginUserVo[] = []
  totalCount = 0

  @Mutation
  SET_MEMBER_LIST (data: ReqGetMemberList['Response']) {
    this.totalCount = data.totalCount
    this.memberList = data.data
  }

  @Mutation
  APPEND_MEMBER (employee: LoginUserVo) {
    if (requestData && requestData.page === 0) {
      const len = this.memberList.unshift(employee)
      if (len > requestData.size) {
        this.memberList.pop()
      }
    }
    this.totalCount += 1
  }

  @Mutation
  CHANGE_MEMBER (result:any) {
    const target = this.memberList.findBy('userId', result.userId)
    JlinkUtils.obj.dataSet(result, target)
  }

  @Mutation
  UPDATE_LICENSE (param: { license: FlyLicenseVo; userId: string }) {
    const target = this.memberList.findBy('userId', param.userId)
    target && (target.userLicense = param.license)
  }

  @Action({ rawError: true })
  async getList (data: ReqGetMemberList['Request']) :Promise<void> {
    if (data.page < 0) {
      console.debug('page must >=0')
      return Promise.resolve()
    }
    requestData = data
    const res = await getMemberList(data)
    this.SET_MEMBER_LIST(res)
  }

  @Action({ rawError: true })
  async addMember (data: ReqAddMember['Request']) :Promise<void> {
    const res = await addMember(data)
    this.APPEND_MEMBER(res)
  }

  @Action({ rawError: true })
  async deleteMember (data: ReqDeleteMember['Request']): Promise<void> {
    await deleteMember(data)
    if (requestData) {
      await this.getList(requestData)
    }
  }

  @Action({ rawError: true })
  async changeMemberStatus (data:{userId:string, userStatus:USER_STATUS}): Promise<void> {
    const result = await modifyUser({ userId: data.userId, userStatus: data.userStatus })
    this.CHANGE_MEMBER(result)
  }

  @Action({ rawError: true })
  async changeMemberAsAdmin (data:{userId:string, admin:boolean}): Promise<void> {
    const result = await changeRoleAsAdmin({ userId: data.userId, admin: data.admin })
    this.CHANGE_MEMBER(result)
  }
}

export const MembersModule = getModule(Members)
