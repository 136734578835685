import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2fdb8df3"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "deep-media-file-image"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$setup.extType === 'video' ? (_openBlock(), _createBlock($setup["DeepFileVideoImage"], {
    key: 0,
    "class": "image",
    src: $setup.url,
    lazy: ""
  }, null, 8 /* PROPS */, ["src"])) : _createCommentVNode("v-if", true), $setup.extType === 'image' ? (_openBlock(), _createBlock($setup["DeepFileImage"], {
    key: 1,
    "class": "image",
    src: $setup.url,
    lazy: ""
  }, null, 8 /* PROPS */, ["src"])) : _createCommentVNode("v-if", true), $setup.extType === 'unknown' ? (_openBlock(), _createBlock($setup["DeepFileImage"], {
    key: 2,
    "class": "image"
  })) : _createCommentVNode("v-if", true)]);
}