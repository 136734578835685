import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store/index'
import { addDrone, checkFirmware, deleteDrone, getDroneListByPage, modifyDrone } from '@/api/api.drone'
import JlinkUtils from '@/common/global/JlinkUtils'
interface IParamsState {
  dockList: DockVo[];
  totalCount: number;
}
let requestData: ReqGetDroneListByPage['Request'] | undefined

@Module({ name: 'dockList', dynamic: true, namespaced: true, store })
class DockList extends VuexModule implements IParamsState {
  dockList:DockVo[] = []
  totalCount = 0

  @Mutation
  SET_DRONE_LIST (data: ReqGetDroneListByPage['Response']) {
    this.totalCount = data.totalCount
    this.dockList = data.data as DockVo[]
  }

  @Mutation
  CHANGE_DOCK (data: DroneBaseVo) {
    const target = this.dockList.findBy('droneId', data.droneId)
    JlinkUtils.obj.dataSet(data, target)
  }

  @Action({ rawError: true })
  async getList (data: Omit<ReqGetDroneListByPage['Request'], 'gatewayDomain'>) :Promise<void> {
    requestData = { gatewayDomain: '3', ...data }
    const res = await getDroneListByPage(requestData)
    this.SET_DRONE_LIST(res)
  }

  @Action({ rawError: true })
  async addDock (data: ReqAddDrone['Request']) :Promise<void> {
    await addDrone(data)
    if (requestData) {
      await this.getList(requestData)
    }
  }

  @Action({ rawError: true })
  async modifyDock (data: ReqDroneUpdate['Request']):Promise<void> {
    const res = await modifyDrone(data)
    this.CHANGE_DOCK(res)
  }

  @Action({ rawError: true })
  async checkFirmware (data: ReqCheckFirmware['Request']):Promise<void> {
    const res = await checkFirmware(data)
    this.CHANGE_DOCK(res)
  }

  @Action({ rawError: true })
  async deleteDock (data: ReqDeleteDrone['Request']): Promise<void> {
    await deleteDrone(data)
    if (requestData) {
      await this.getList(requestData)
    }
  }
}

export const DockListModule = getModule(DockList)
