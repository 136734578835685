import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store/index'
import { addDrone, deleteDrone, getDroneListByPage, modifyDrone } from '@/api/api.drone'
import JlinkUtils from '@/common/global/JlinkUtils'

let requestData: ReqGetDroneListByPage['Request'] | undefined

@Module({ name: 'droneList', dynamic: true, namespaced: true, store })
class DroneList extends VuexModule {
  droneList: DroneBaseVo[] = []
  totalCount = 0
  @Mutation
  SET_DRONE_LIST (data: ReqGetDroneListByPage['Response']) {
    this.totalCount = data.totalCount
    this.droneList = data.data
  }

  @Mutation
  APPEND_DRONE (drone: DroneBaseVo) {
    if (requestData && requestData.page === 0) {
      const len = this.droneList.unshift(drone)
      if (len > requestData.size) {
        this.droneList.pop()
      }
    }
    this.totalCount += 1
  }

  @Mutation
  CHANGE_DRONE (data:DroneBaseVo) {
    const target = this.droneList.findBy('droneId', data.droneId)
    if (target) {
      JlinkUtils.obj.dataSet(data, target)
    }
  }



  @Action({ rawError: true })
  async getList (data: ReqGetDroneListByPage['Request']) :Promise<void> {
    if (data.page < 0) {
      console.debug('page must >=0')
      return Promise.resolve()
    }
    requestData = data
    const res = await getDroneListByPage(requestData)
    this.SET_DRONE_LIST(res)
  }

  @Action({ rawError: true })
  async addDrone (data: ReqAddDrone['Request']) :Promise<void> {
    const res = await addDrone(data)
    this.APPEND_DRONE(res)
  }

  @Action({ rawError: true })
  async modifyDrone (data: ReqDroneUpdate['Request']):Promise<DroneBaseVo> {
    const res = await modifyDrone(data)
    this.CHANGE_DRONE(res)
    return res
  }

  @Action({ rawError: true })
  async deleteDrone (data: ReqDeleteDrone['Request']): Promise<void> {
    await deleteDrone(data)
    if (requestData) {
      await this.getList(requestData)
    }
  }
}

export const DroneListModule = getModule(DroneList)
