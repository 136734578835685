import { createRouter, createWebHistory } from 'vue-router'
import JlinkRouter from '@/common/global/JlinkRouter'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => ({ top: 0 }),
  routes: []
})

router.beforeEach((to, from, next) => {
  JlinkRouter.beforeEach(to, from, next)
})
router.afterEach(() => {
})

export default router
