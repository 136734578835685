import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import store from '@/store/index'
import { getDroneListByPage } from '@/api/api.drone'
import { DroneListModule } from '@/store/DroneList'
import { getDronePartsListByPage } from '@/api/api.drone.parts'
import { getMemberList } from '@/api/api.user'
import JlinkMqtt from '@/common/global/JlinkMqtt'

@Module({ name: 'companyAll', dynamic: true, namespaced: true, store })
class CompanyAll extends VuexModule {
  droneData: {
    totalCount: number,
    droneList: DroneBaseVo[]
  } = {
      totalCount: 0,
      droneList: []
    }

  dronePartsData: {
    totalCount: number,
    dronePartsList: DronePartsBaseVo[]
  } = {
      totalCount: 0,
      dronePartsList: []
    }

  memberData: {
    totalCount: number,
    memberList: LoginUserVo[]
  } = {
      totalCount: 0,
      memberList: []
    }

  @Mutation
  SET_DRONE_LIST (data: ReqGetDroneListByPage['Response']) {
    this.droneData.totalCount = data.totalCount
    this.droneData.droneList = data.data
  }

  @Mutation
  SET_DRONE_PARTS_LIST (data: ReqGetDronePartsListByPage['Response']) {
    this.dronePartsData.totalCount = data.totalCount
    this.dronePartsData.dronePartsList = data.data
  }

  @Mutation
  SET_MEMBER_LIST (data: ReqGetMemberList['Response']) {
    this.memberData.totalCount = data.totalCount
    this.memberData.memberList = data.data
  }

  @Action({ rawError: true })
  async getDroneList (data: ReqGetDroneListByPage['Request']) :Promise<void> {
    console.log('vvve1', data)
    if (data.page < 0) {
      console.debug('page must >=0')
      return Promise.resolve()
    }
    const res = await getDroneListByPage(data)
    console.log('vvve2', res)
    this.SET_DRONE_LIST(res)
  }

  @Action({ rawError: true })
  async getDronePartsList (data: ReqGetDronePartsListByPage['Request']): Promise<void> {
    console.log('vvvp2', data)
    if (data.page < 0) {
      console.debug('page must >=0')
      return Promise.resolve()
    }
    const res = await getDronePartsListByPage(data)
    console.log('vvvp3', data, res)
    this.SET_DRONE_PARTS_LIST(res)
  }

  @Action({ rawError: true })
  async getMemberList (data: ReqGetMemberList['Request']) :Promise<void> {
    if (data.page < 0) {
      console.debug('page must >=0')
      return Promise.resolve()
    }
    const res = await getMemberList(data)
    this.SET_MEMBER_LIST(res)
  }
}

export const CompanyAllModule = getModule(CompanyAll)
