import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import store from '@/store/index'
import { addChildCompany, deleteCompany, getAllCompany, updateCompany } from '@/api/api.company'
import { AppConfigModule } from '@/store/AppConfig'
import { UserModule } from '@/store/User'
import JlinkUtils from '@/common/global/JlinkUtils'
let requestData: ReqGetAllCompany['Request'] | undefined
@Module({ name: 'company', dynamic: true, namespaced: true, store })
class Company extends VuexModule {
  companyList: CompanyVo[] = []
  totalCount = 0

  @Mutation
  SET_COMPANY_LIST (data: ReqGetAllCompany['Response']) {
    this.totalCount = data.totalCount
    this.companyList = data.data
  }

  @Mutation
  CHANGE_COMPANY (data: CompanyVo) {
    const target = this.companyList.findBy('companyId', data.companyId)
    JlinkUtils.obj.dataSet(data, target)
    if (data.companyId === UserModule.user.companyId) {
      AppConfigModule.MERGE_COMPANY(data)
    }
  }

  @Action({ rawError: true })
  async getList (data: ReqGetAllCompany['Request']) : Promise<void> {
    requestData = data
    const res = await getAllCompany(data)
    this.SET_COMPANY_LIST(res)
  }

  @Action({ rawError: true })
  async add (data: ReqAddChildCompany['Request']) : Promise<void> {
    await addChildCompany(data)
    if (requestData) {
      await this.getList(requestData)
    }
  }

  @Action({ rawError: true })
  async update (data: ReqUpdateCompany['Request']): Promise<void> {
    const res = await updateCompany(data)
    this.CHANGE_COMPANY(res)
  }

  @Action({ rawError: true })
  async delete (data: ReqDeleteCompany['Request']): Promise<void> {
    await deleteCompany(data)
    if (requestData) {
      await this.getList(requestData)
    }
  }
}

export const CompanyModule = getModule(Company)
