export const WEB_DEFAULT = {
  path: '/',
  name: 'default',
  fullPath: '/'
}
export const WEB_EMERGENCY = {
  path: '/emergencyTasks',
  name: 'emergencyTasks',
  fullPath: '/emergencyTasks'
}

export const WEB_WAYLINE_EDITOR = {
  path: '/waylineEditor',
  name: 'waylineEditor',
  fullPath: '/waylineEditor'
}

export const WEB_FLIGHT_CONTROL = {
  path: '/flightControl',
  name: 'flightControl',
  fullPath: '/flightControl'
}

export const WEB_MAIN = {
  path: 'main',
  name: 'main',
  fullPath: '/main'
}

export const WEB_LIBRARY = {
  path: 'library',
  name: 'library',
  fullPath: '/library'
}

export const WEB_GROUP = {
  path: 'group',
  name: 'group',
  fullPath: '/group'
}
