import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store/index'
import JlinkStorage from '@/common/global/JlinkStorage'
import JlinkRouter from '@/common/global/JlinkRouter'
import JlinkMqtt2 from '@/common/global/JlinkMqtt2'
import { DroneJoystick } from '@/common/mqtt/jlink/DrcServiceMqtt'
import JlinkUtils from '@/common/global/JlinkUtils'
import { translate } from 'element-plus'

@Module({ name: 'FlightControl', dynamic: true, namespaced: true, store })
class FlightControl extends VuexModule {
  // 调参区域
  tunesSetting: FlightParam = JlinkStorage.get('flight_param') || {
    VirtualRockerAdjustment: {
      horizontalSpeed: 10,
      verticalSpeed: 5,
      palstance: 10,
    },
    takeOffAdjustment: {
      targetHeightAbsolute: false,
      securityTakeoffHeight: 150,
      maxSpeed: 10,
      targetHeight: 150,
      rthAltitude: 150,
    },
    flyToAdjustment: {
      maxSpeed: 10
    }
  }

  controlService:{
    controlType?:0|1|2
    support?:Set<number>
    joystickSupport?:boolean
    isActive?:boolean
  } = {}

  data:{
    currentDrone?:DroneBaseVo
    droneList?:DroneBaseVo[]
    aiOpen:boolean
    returnHomeCancelAble: boolean,
  } = {
      returnHomeCancelAble: false,
      aiOpen: false,
    }

  // 退出飞行控制
  @Action({ rawError: true })
  destory () {
    this.RESET()
  }

  // 进入飞行控制模式
  @Action({ rawError: true })
  async control (data:{ currentDrone: DroneBaseVo, droneList:DroneBaseVo[] }) {
    this.RESET()
    FlightControlModule.data.currentDrone = data.currentDrone
    FlightControlModule.data.droneList = data.droneList
    await JlinkRouter.goPageFlightControl()
  }

  @Mutation
  UPDATE_FLIGHT_PARAM (param: FlightParam) {
    JlinkStorage.set('flight_param', param)
  }

  @Mutation
  RESET (): void {
    this.controlService = {}
    this.data = {
      aiOpen: false,
      returnHomeCancelAble: false,
    }
    this.pointsControl = {
      searchType: '0',
      controlPointsList: []
    }
  }

  /* 指点飞行中点的控制 */
  pointsControl:{
    searchType:'0'|'1'|'2'
    currentDingData?:ControlPoints
    controlPointsList: ControlPoints[]
  } = {
      searchType: '0',
      controlPointsList: []
    }

  get pointDingAble () {
    return this.pointsControl.searchType === '0'
  }

  get controlPointsList () {
    return this.pointsControl.controlPointsList
  }

  @Mutation
  pickPoints (point:Omit<ControlPoints, 'height'>) {
    const target = { ...point, height: FlightControlModule.tunesSetting.takeOffAdjustment.targetHeight }
    FlightControlModule.pointsControl.controlPointsList.push(target)
    this.pointsControl.currentDingData = target
  }

  // 删除点
  @Mutation
  DELETE_CONTROL_POINT (id: string): void {
    this.pointsControl.controlPointsList.removeIf('id', id)
    if (this.pointsControl.currentDingData?.id === id) {
      this.pointsControl.currentDingData = undefined
    }
  }
}

export const FlightControlModule = getModule(FlightControl)
