import { createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["DeepImage"], {
    "class": "deep-drone-image",
    fit: "contain",
    src: $setup.images,
    lazy: $props.lazy,
    "is-oss": false
  }, {
    "default": _withCtx(function () {
      return [_createCommentVNode("    <DeepSvgIcon   name=\"icon_dock\" colorFull style=\"width: 100%;height: 100%\"></DeepSvgIcon>")];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["src", "lazy"]);
}