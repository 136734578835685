import Layout from '@/app/layout/WebLayout.vue'
import { RouteRecordRaw } from 'vue-router'

/* 调试路由 */
const devRoutes: RouteRecordRaw[] = [
  {
    path: '/dev',
    component: Layout,
    name: 'dev',
    meta: { title: '开发辅助', icon: 'file-code-line' },
    redirect: '/dev/document',
    children: [
      {
        path: 'icons',
        component: () => import('@/app/views/pages/dev/icons/index.vue'),
        name: '图标库',
        meta: { title: '图标库', icon: 'link', noCache: true }
      },

      {
        name: 'Vue外连接',
        component: () => import('@/app/layout/EmptyLayout.vue'),
        path: 'https://panjiachen.github.io/vue-element-admin-site/#/',
        meta: { title: 'Vue外连接', icon: 'link' }
      },
      {
        path: 'document',
        name: '文档页',
        component: () => import('@/app/views/pages/dev/document/index.vue'),
        meta: { title: '文档页', icon: 'dashboard' }
      },
      {
        path: 'test',
        name: '测试页',
        component: () => import('@/app/views/pages/dev/test/index.vue'),
        meta: { title: '测试页', icon: 'dashboard' }
      }
    ]
  },
]

export default devRoutes
