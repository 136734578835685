import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import store from '@/store/index'
import { deleteSeneneMessage, getSenenMessageListByPage } from '@/api/api.notify'
import JlinkUi from '@/common/global/JlinkUi'

@Module({ name: 'UserNotifyMessage', dynamic: true, namespaced: true, store })
class UserNotifyMessage extends VuexModule {
  totalCount = 0
  newArrived: boolean = false
  removeCallback?: (messageId: String) => void
  appendCallback?: (message: NotifyMessageBase<any>) => void
  updateCallback?: (message: NotifyMessageBase<any>) => void

  @Mutation
  HANDLE_NEW_ARRIVE (m: { newCount:number}) {
    this.newArrived = m.newCount > 0
  }

  @Mutation
  HANDLE_MESSAGE (message: NotifyMessageBase<unknown>) {
    // 普通消息
    if (message.bizCode === 0) {
      // 通知消息
    } else if (message.bizCode === 1) {
      if (message.title && message.content) {
        JlinkUi.baseNotify(message.title, message.content)
      }
      this.totalCount += 1
      this.appendCallback && this.appendCallback(message)
    }
  }

  @Mutation
  SET_APPEND_CALLBACK (appendCallback?: (message: NotifyMessageBase<any>) => void) {
    this.appendCallback = appendCallback
  }

  @Mutation
  SET_REMOVE_CALLBACK (removeCallback?: (messageId: String) => void) {
    this.removeCallback = removeCallback
  }

  @Mutation
  SET_UPDATE_CALLBACK (updateCallback?: (message: NotifyMessageBase<any>) => void) {
    this.updateCallback = updateCallback
  }

  @Mutation
  SET_MARK_READ (messageId: string) {
    // @ts-ignore
    this.updateCallback && this.updateCallback({ messageId, newFlag: false })
  }

  @Mutation
  SET_TOTAL_COUNT (totalCount: number) {
    this.totalCount = totalCount
  }

  @Mutation
  DELETE_MESSAGE (messageId: string) {
    this.totalCount -= 1
    this.removeCallback && this.removeCallback(messageId)
  }

  @Action({ rawError: true })
  async getMessages (data: { page: number, size: number }): Promise<NotifyMessageBase<any>[]> {
    const res = await getSenenMessageListByPage(data)
    this.SET_TOTAL_COUNT(res.totalCount)
    return res.data
  }

  @Action({ rawError: true })
  async deleteMessage (messageId: string): Promise<void> {
    await deleteSeneneMessage({ messageId })
    this.DELETE_MESSAGE(messageId)
  }
}

export const UserNotifyMessageModule = getModule(UserNotifyMessage)
