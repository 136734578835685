import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import store from '@/store/index'
import {
  addBusinessPackage,
  getBusinessPackageList,
  updateBusinessPackage,
  deleteBusinessPackage,
  refreshBusinessPackage
} from '@/api/api.business'
import JlinkUtils from '@/common/global/JlinkUtils'

interface IParamsState {
  businessPackageList: BusinessPackageVo[];
  totalCount: number;
}

let requestData: ReqGetBusinessPackageList['Request'] | undefined

@Module({ name: 'BusinessPackage', dynamic: true, namespaced: true, store })
class BusinessPackage extends VuexModule implements IParamsState {
  businessPackageList: BusinessPackageVo[] = []
  totalCount = 0

  @Mutation
  SET_BUSINESSP_ACKAGE_LIST (data: ReqGetBusinessPackageList['Response']) {
    this.totalCount = data.totalCount
    this.businessPackageList = data.data
  }

  @Mutation
  CHANGE_BUSINESSP_ACKAGE (data: BusinessPackageVo) {
    const target = this.businessPackageList.findBy('businessId', data.businessId)
    JlinkUtils.obj.dataSet(data, target)
  }

  @Action({ rawError: true })
  async getList (data: ReqGetBusinessPackageList['Request']) :Promise<void> {
    if (data.page < 0) {
      console.debug('page must >=0')
      return Promise.resolve()
    }
    requestData = data
    const res = await getBusinessPackageList(data)
    this.SET_BUSINESSP_ACKAGE_LIST(res)
  }

  @Action({ rawError: true })
  async addBusinessPackage (data: ReqAddBusinessPackage['Request']):Promise<void> {
    await addBusinessPackage(data)
    if (requestData) {
      await this.getList(requestData)
    }
  }

  @Action({ rawError: true })
  async updateBusinessPackage (data: ReqUpdateBusinessPackage['Request']):Promise<void> {
    const res = await updateBusinessPackage(data)
    this.CHANGE_BUSINESSP_ACKAGE(res)
  }

  @Action({ rawError: true })
  async deleteBusinessPackage (data: ReqDeleteBusinessPackage['Request']): Promise<void> {
    await deleteBusinessPackage(data)
    if (requestData) {
      await this.getList(requestData)
    }
  }

  @Action({ rawError: true })
  async refresh (): Promise<void> {
    await refreshBusinessPackage(undefined)
    if (requestData) {
      await this.getList(requestData)
    }
  }
}

export const BusinessPackageModule = getModule(BusinessPackage)
