import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store/index'
import {
  endAssignments,
  enterAssignments,
  leaveAssignments,
  startAssignments
} from '@/api/api.assignments'
import { UserModule } from '@/store/User'
import NotifyException from '@/common/errors/NotifyException'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkUi from '@/common/global/JlinkUi'
import JlinkType from '@/common/global/JlinkType'
import { computed, ref, toRaw } from 'vue'
import GroupLabel from '@/app/views/pages/assignments/group/GroupLabel.vue'
import GroupMonitor from '@/app/views/pages/assignments/group/GroupMonitor.vue'
import MediaLabel from '@/app/views/pages/assignments/media/MediaLabel.vue'
import MediaMonitor from '@/app/views/pages/assignments/media/MediaMonitor.vue'
import JobLabel from '@/app/views/pages/assignments/job/JobLabel.vue'
import JobMonitor from '@/app/views/pages/assignments/job/JobMonitor.vue'
import JobEditor from '@/app/views/pages/assignments/job/JobEditorLabel.vue'
import JobEditorMonitor from '@/app/views/pages/assignments/job/JobEditorMonitor.vue'
import JlinkMqtt from '@/common/global/JlinkMqtt'

enum PanelLabelIndex {
  GROUP,
  MEDIA,
  DOCUMENT,
  PLAN,
  PLAN_EDITOR,
  SITUATION,
  GO_BACK,
}

@Module({ name: 'AssignmentsOpen', dynamic: true, namespaced: true, store })
class AssignmentsOpen extends VuexModule {
  /* -----------------------------label切换start------------------------------ */
  get labelItems () {
    if (this.assignmentsId) {
      const commonList :{
        key: PanelLabelIndex
        name: string,
        icon: string,
        hidden: boolean,
        left: Function,
        right: Function
      }[] = [
        {
          key: PanelLabelIndex.GROUP,
          name: '团队',
          icon: 'svg-team',
          hidden: false,
          left: () => toRaw(GroupLabel),
          right: () => toRaw(GroupMonitor)
        },
        {
          key: PanelLabelIndex.MEDIA,
          name: '媒体库',
          icon: 'film',
          hidden: false,
          left: () => toRaw(MediaLabel),
          right: () => toRaw(MediaMonitor)
        }
      ]
      if (JlinkType.assignments.isDockType(AssignmentsOpenModule.data.assignments) && JlinkType.user.isAdmin.value) {
        commonList.push({
          key: PanelLabelIndex.PLAN,
          name: '计划库',
          icon: 'bill',
          hidden: false,
          left: () => toRaw(JobLabel),
          right: () => toRaw(JobMonitor)
        })
        commonList.push({
          key: PanelLabelIndex.PLAN_EDITOR,
          name: '计划库编辑',
          icon: 'bill',
          hidden: true,
          left: () => toRaw(JobEditor),
          right: () => JobEditorMonitor
        })
      }
      return commonList
    } else {
      return []
    }
  }

  get labelCurrent () {
    return this.labelItems.findBy('key', this.data.currentLabelIndex)
  }

  @Mutation
  TO_LABEL (index: PanelLabelIndex) {
    this.data.currentLabelIndex = index
  }

  @Mutation
  TO_LABEL_PLAN_EDITOR (job?: JobEditData) {
    console.log(this.data.assignments)
    if (AssignmentsOpenModule.assignmentsId) {
      this.data.JobEditorData = job || {
        jobId: undefined,
        jobName: '新建计划',
        cronExpression: '?',
        waylineId: '',
        droneId: '',
        jobType: 0,
        jobExpressionType: 0,
        assignmentsId: AssignmentsOpenModule.assignmentsId,
        jobDrone: undefined,
        jobWayline: undefined,
        rthAltitude: 100,
        objectDetected: false,
        breakPointContinue: false
      }
      this.data.currentLabelIndex = PanelLabelIndex.PLAN_EDITOR
    } else {
      throw new NotifyException('数据错误', 'error')
    }
  }

  @Mutation
  TO_LABEL_PLAN () {
    this.data.currentLabelIndex = PanelLabelIndex.PLAN
  }

  @Mutation
  async TO_LABEL_MEDIA (data: { drone?: DroneBaseVo, taskId?: string }) {
    this.data.currentMediaSelect = data.drone || this.drones[0]
    this.data.currentLabelIndex = PanelLabelIndex.MEDIA
  }

  @Mutation
  TOGGLE_DOCK_DETAIL (drone?: DroneBaseVo) {
    if (drone) {
      this.data.dockDetailShow = drone
    } else {
      delete this.data.dockDetailShow
    }
  }

  @Mutation
  TOGGLE_AIRCRAFT_DETAIL (drone?: DroneBaseVo) {
    if (drone) {
      this.data.aircraftDetailShow = drone
    } else {
      delete this.data.aircraftDetailShow
    }
  }

  /* -----------------------------机场信息切换end------------------------------ */

  data: {
    // job编辑数据
    JobEditorData?: JobEditData
    // 任务数据
    assignments?: AssignmentsVo
    // 当前选中的飞机媒体
    currentMediaSelect?: DroneBaseVo
    // 显示机场信息面板
    dockDetailShow?: DroneBaseVo
    // 当前选中的飞机
    currentSelectGatewaySn?:string
    // 显示飞行器信息面板
    aircraftDetailShow?: DroneBaseVo
    // 临时用户
    tempMembers: UserShowWrapperVo[]
    // 当前任务面板索引
    currentLabelIndex :PanelLabelIndex
    // 最后一条消息
    lastMessage?:AssignmentsMessageVo
    // 跟随的飞机
    follow?: string
    // 消息列表
    receiveMessageList: AssignmentsMessageVo[]
    // 开启的轨迹记录
    trail: Set<string>

  } = {
      receiveMessageList: [],
      tempMembers: [],
      trail: new Set<string>(),
      currentLabelIndex: PanelLabelIndex.GROUP,
    }

  get assignmentsId () {
    return this.data.assignments?.assignmentsId
  }

  get assignmentsName () {
    return this.data.assignments?.assignmentsName || '未知任务'
  }

  get currentSelectDrone () {
    return this.drones.findBy('gatewaySerialNumber', this.data.currentSelectGatewaySn)
  }

  get isAssignmentsAdmin () {
    return JlinkType.assignments.isAssignmentsAdmin(this.data.assignments)
  }

  get assignmentsStatus () {
    return this.data.assignments?.assignmentsStatus || 0
  }

  get drones () {
    return this.data.assignments?.assignmentsDrones || []
  }

  get members ():UserShowWrapperVo[] {
    return this.data.assignments?.assignmentsMembers as UserShowWrapperVo[] || []
  }

  get droneParts () {
    return this.data.assignments?.assignmentsDroneParts || []
  }

  get documents () {
    return this.data.assignments?.assignmentsDocuments || []
  }

  @Mutation
  SET_ASSIGNMENTS_DETAIL (assignments?: AssignmentsVo) {
    this.data.assignments = assignments
    this.data = {
      assignments,
      receiveMessageList: [],
      tempMembers: [],
      trail: new Set<string>(),
      currentLabelIndex: PanelLabelIndex.GROUP,
      currentMediaSelect: assignments?.assignmentsDrones[0]
    }
  }

  // 开启或者关闭轨迹
  @Mutation
  CHANGE_DRONE_TRAIL (data: { drone: DroneBaseVo, open: boolean }) {
    if (data.open) {
      this.data.trail.add(data.drone.aircraftSerialNumber)
    } else {
      this.data.trail.delete(data.drone.aircraftSerialNumber)
    }
  }

  @Mutation
  CHANGE_DRONE_FOLLOW (drone?: DroneBaseVo) {
    this.data.follow = drone?.aircraftSerialNumber || ''
  }

  @Mutation
  CHANGE_ASSIGNMENTS (assignments: AssignmentsBaseVo | {
    assignmentsId: string,
    assignmentsType: number,
    assignmentsStatus: number,
    state: 0 | 1 | 2 | 3 | 4
  }) {
    if (assignments.assignmentsId === AssignmentsOpenModule.assignmentsId) {
      JlinkUtils.obj.dataSet(assignments, this.data.assignments)
    }
  }

  // 更新飞机在线状态
  @Mutation
  CHANGE_DRONE_ONLINE (data: MqttDroneOnlineResult) {
    this.drones.forEach(item => {
      if (item.gatewaySerialNumber === data.gatewaySerialNumber) {
        JlinkUtils.obj.dataSet(data, item)
      }
    })
  }

  // 选中飞机
  @Mutation
  SET_SELECTED (drone?: DroneBaseVo) {
    this.data.currentSelectGatewaySn = drone?.gatewaySerialNumber
  }

  @Mutation
  msgHandler (msg: AssignmentsMessageVo) {
    this.data.lastMessage = msg
    this.data.receiveMessageList.push(this.data.lastMessage)
    if (this.data.receiveMessageList.length > 6) {
      this.data.receiveMessageList.shift()
    }
  };

  // 进入任务
  @Action({ rawError: true })
  async enter (data: AssignmentsBaseVo): Promise<void> {
    // 如果任务开启加密只有管理员或者用户自己创建的任务可以免密通过
    if (data.assignmentsVerification) {
      if (!AssignmentsOpenModule.isAssignmentsAdmin) {
        const result = await JlinkUi.baseConfirm('该任务属于加密任务，请输入任务密码', '输入密码', {
          showInput: true,
          inputPlaceholder: '请输入任务密码',
          customStyle: { 'min-width': '30rem' }
        })
        if (result.action !== 'confirm') return
        if (result.value !== data.assignmentsVerificationCode) {
          throw new NotifyException('任务密码错误', 'error')
        }
      }
    }
    const detail = await enterAssignments({
      assignmentsId: data.assignmentsId,
      assignmentsVerificationCode: data.assignmentsVerificationCode
    })
    this.SET_ASSIGNMENTS_DETAIL(detail)
    await JlinkMqtt.subscribeUserInAssignments(detail.assignmentsId, UserModule.user.userId, detail.assignmentsToken, async function (msg) {
      const members = AssignmentsOpenModule.members
      const msgUsers = msg as UserShowWrapperVo[]
      const timestamp = Date.now()
      members.forEach(it => {
        for (const i of msgUsers) {
          if (it.userId === i.userId) {
            it.isEnter = true
            it.loginCount = i.loginCount
            it.updateDate = timestamp
            i.include = true
            break
          }
        }
      })
      await AssignmentsOpenModule.data.tempMembers.diffTo(msgUsers, function (s, t, from) {
        if (s.userId === t.userId) {
          s.isEnter = true
          s.updateDate = timestamp
          return true
        }
        return false
      }, function (sl) {
        AssignmentsOpenModule.data.tempMembers.removeIf('userId', sl.mapBy('userId'))
      }, function (sl) {
        sl.forEach(it => {
          if (!it.include) {
            AssignmentsOpenModule.data.tempMembers.push(it)
          }
        })
      }, function (sl) {
        return { ...sl, isEnter: true, updateDate: timestamp }
      })
    })
    await JlinkMqtt.subscribeAssignmentsMessage(data.assignmentsId, true, this.msgHandler)
  }

  // 离开任务
  @Action({ rawError: true })
  async leave (): Promise<void> {
    if (this.data.assignments && AssignmentsOpenModule.assignmentsId) {
      await leaveAssignments({ assignmentsId: AssignmentsOpenModule.assignmentsId, assignmentsToken: this.data.assignments.assignmentsToken })
      await JlinkMqtt.subscribeAssignmentsMessage(AssignmentsOpenModule.assignmentsId, false, this.msgHandler)
      await JlinkMqtt.subscribeUserInAssignments(AssignmentsOpenModule.assignmentsId, UserModule.user.userId, this.data.assignments.assignmentsToken, undefined)
      this.SET_ASSIGNMENTS_DETAIL()
    }
  }

  @Action({ rawError: true })
  async start (): Promise<void> {
    if (this.assignmentsId) {
      await startAssignments({ assignmentsId: this.assignmentsId })
    } else {
      throw new NotifyException('数据错误', 'error')
    }
  }

  @Action({ rawError: true })
  async end (assignmentsSummary: string): Promise<void> {
    if (this.assignmentsId) {
      await endAssignments({ assignmentsId: this.assignmentsId, assignmentsSummary })
    } else {
      throw new NotifyException('数据错误', 'error')
    }
  }
}

export const AssignmentsOpenModule = getModule(AssignmentsOpen)
