import { getModule, Module, Mutation, VuexModule, Action } from 'vuex-module-decorators'
import store from '@/store/index'
import { getCompanyDetail } from '@/api/api.company'
import JlinkStorage from '@/common/global/JlinkStorage'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkValues from '@/common/global/JlinkValues'
import NeedReLoginException from '@/common/errors/NeedRLoginException'
import * as process from 'process'
import { DjiDeviceEnum } from '@/common/dji/DjiDeviceEnum'
type GlobalConfig = {
  // 是否开启联网地图
  exteralMapSelect: boolean
  // 服务器

  host: string
  // 地图
  mapId: string
  // 地形
  terrainId?: string
}

@Module({ name: 'CompanyConfig', dynamic: true, namespaced: true, store })
class AppConfig extends VuexModule {
  data: {
    company?: CompanyDetailVo
  } = {
      company: JlinkStorage.get('Company')
    }

  get appVersion () {
    return process.env.VUE_APP_VERSION || 'known'
  }

  get company () {
    if (this.data.company) {
      return this.data.company
    } else {
      throw new NeedReLoginException('company 信息不存在')
    }
  }

  get hookCallback ():{url?:string, headers?:StringKeyRecord<string>} {
    if (this.company.companyHookUrl) {
      try {
        const res = JSON.parse(this.company.companyHookUrl)
        return { url: res.url, headers: res.headers }
      } catch (e) {
        return {}
      }
    } else {
      return {}
    }
  }

  get companyDiskPath () {
    return this.company.companyDiskPath
  }

  get commonPath () {
    return this.company.commonPath
  }

  get cloudUploadPath () {
    return this.company.cloudUploadPath
  }

  get liveRecordPath () {
    return this.company.liveRecordPath
  }

  get tradPath () {
    return this.company.tradPath
  }

  get assignmentsFilePath () {
    return this.company.assignmentsFilePath
  }

  get firmwareFilePath () {
    return this.company.firmwareFilePath
  }

  get sharePath () {
    return this.company.sharePath
  }

  /* bigemap 相关 */
  // bigemap默认定位
  //  // TODO 地图默认全局坐标（后期完善为用户登陆ip的坐标信息）
  mapCenter: JlinkLocation & { readonly address: string } = {
    lat: 29.88481040,
    lng: 121.63587104,
    height: 5000,
    address: 'DJI大疆无人机体验店'
  }

  get bigmapGlobalConfig (): StringKeyRecord<GlobalConfig> {
    return {
      config_arrange: {
        exteralMapSelect: false,
        host: process.env.VUE_APP_BIGEMAP_HOST!,
        mapId: process.env.VUE_APP_BIGEMAP_MAP_ID!,
        terrainId: process.env.VUE_APP_BIGEMAP_TERRAIN_ID
      },
      config_development: {
        exteralMapSelect: false,
        host: process.env.VUE_APP_BIGEMAP_HOST!,
        mapId: process.env.VUE_APP_BIGEMAP_MAP_ID!,
        terrainId: process.env.VUE_APP_BIGEMAP_TERRAIN_ID
      },
      config_preview: {
        exteralMapSelect: false,
        host: process.env.VUE_APP_BIGEMAP_HOST!,
        mapId: process.env.VUE_APP_BIGEMAP_MAP_ID!,
        terrainId: process.env.VUE_APP_BIGEMAP_TERRAIN_ID
      },
      config_production: {
        exteralMapSelect: false,
        host: process.env.VUE_APP_BIGEMAP_HOST!,
        mapId: process.env.VUE_APP_BIGEMAP_MAP_ID!,
        terrainId: process.env.VUE_APP_BIGEMAP_TERRAIN_ID
      },
      config_stage: {
        exteralMapSelect: false,
        host: process.env.VUE_APP_BIGEMAP_HOST!,
        mapId: process.env.VUE_APP_BIGEMAP_MAP_ID!,
        terrainId: process.env.VUE_APP_BIGEMAP_TERRAIN_ID
      }
    }
  }

  // Google地图源
  exteralMapUrl = 'https://webst04.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}'
  exteralMapUse = this.bigemapConfig.exteralMapSelect

  get bigemapConfig () {
    console.log('config path is' + 'config_' + process.env.VUE_APP_ENV_DESC?.toLowerCase())
    const config = this.bigmapGlobalConfig['config_' + process.env.VUE_APP_ENV_DESC?.toLowerCase()]
    const cacheSve = JlinkStorage.get('jlink_bigemap')
    if (cacheSve) {
      config.exteralMapSelect = cacheSve.exteralSelect
    } else {
      JlinkStorage.set('jlink_bigemap', { exteralSelect: config.exteralMapSelect })
    }
    console.log('bigemapConfig', config)
    return config
  }

  @Mutation
  SWITCH_EXTERAL_MAP_USE (use: boolean) {
    const cacheSve = JlinkStorage.get('jlink_bigemap')
    if (cacheSve) {
      cacheSve.exteralSelect = use
      JlinkStorage.set('jlink_bigemap', cacheSve)
    } else {
      JlinkStorage.set('jlink_bigemap', { exteralSelect: use })
    }
    this.exteralMapUse = use
  }

  get isDevDevelopment () {
    return process.env.NODE_ENV?.toLowerCase() === 'development'
  }

  get envDesc () {
    return process.env.VUE_APP_ENV_DESC
  }

  get isDevArrange () {
    return process.env.VUE_APP_ENV_DESC?.toLowerCase() === 'arrange'
  }

  get defaultAircraftSelect () {
    if (this.isDevArrange) {
      return DjiDeviceEnum.deviceEnumKeys.M3TD
    }
    return DjiDeviceEnum.deviceEnumKeys.M30T
  }

  @Mutation
  SET_COMPANY (data: CompanyDetailVo) {
    this.data.company = data
    JlinkStorage.set('Company', data)
  }

  @Mutation
  MERGE_COMPANY (data: CompanyVo) {
    if (data.companyId === this.data.company?.companyId) {
      JlinkUtils.obj.dataSet(data, this.data.company)
    }
  }

  // 登录后必须调用
  @Action({ rawError: true })
  async getDetail (): Promise<void> {
    // 更新公司信息
    const cd = await getCompanyDetail(undefined)
    this.SET_COMPANY(cd)
    // 更新枚举信息
    // const de = await getDeviceEnumList(undefined, false)
    // this.SET_DEVICE_ENUM(de)
  }
}

export const AppConfigModule = getModule(AppConfig)
