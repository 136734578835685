import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-4a5d38a8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "deep-file-video-image"
};
var _hoisted_2 = {
  "class": "autoplay"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode($setup["DeepImage"], {
    fit: "cover",
    src: _ctx.$props.src,
    lazy: _ctx.$props.lazy,
    "class": "videos",
    "oss-resize": ""
  }, null, 8 /* PROPS */, ["src", "lazy"]), _createElementVNode("div", _hoisted_2, [_createVNode($setup["DeepSvgIcon"], {
    "class": "icon",
    name: "play"
  })])]);
}