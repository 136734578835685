import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store/index'
import { getDroneListByPage } from '@/api/api.drone'
import { getAssignmentsList } from '@/api/api.assignments'
import { deleteUploadFiles, getUploadFileByQuery, renameUploadFile } from '@/api/api.uploadFiles'

import _ from 'lodash'
import JlinkUi from '@/common/global/JlinkUi'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkDownload from "@/common/global/JlinkDownload";

@Module({ name: 'Media', dynamic: true, namespaced: true, store })
class MediaLibrary extends VuexModule {
  // 媒体库模块
  droneList: DroneBaseVo[] = []
  assignments: AssignmentsBaseVo[] = []
  checkedMedia: string[] = []
  uploadFiles: UploadFileWrapperVo[] = []
  totalCount: number = 0
  remoteEmp: ReqGetUploadFilesByQuery['Request'] = {
    sortByFileSize: undefined,
    sortByCreateTime: undefined,
    timeRange: undefined,
    assignmentsIds: '',
    droneIds: '',
    page: 1,
    size: 20
  }

  @Mutation
  SET_DRONE_LIST (data: ReqGetDroneListByPage['Response']) {
    this.droneList = data.data
  }

  @Mutation
  SET_ASSIGNMENTS_LIST (data: ReqGetAssignmentsList['Response']) {
    this.assignments = data
  }

  @Mutation
  async SET_MEDIA_LIST (res: TotalReturn<UploadFileWrapperVo>) {
    this.uploadFiles = res.data
    this.totalCount = res.totalCount
  }

  @Mutation
  MEDIA_FILE_CHECKED (data: { files: string[] }) {
    // console.log(data)
    this.checkedMedia = data.files
  }

  @Mutation
  UPDATE_FILE (data: { fileId: string, fileName: string }) {
    const target = this.uploadFiles.findBy('fileId', data.fileId)
    target && (target.fileName = data.fileName)
  }

  @Action({ rawError: true })
  async getDroneList (data: ReqGetDroneListByPage['Request']): Promise<void> {
    const res = await getDroneListByPage(data)
    MediaLibraryModule.SET_DRONE_LIST(res)
  }

  @Action({ rawError: true })
  async getAssignmentList (data: ReqGetAssignmentsList['Request']): Promise<void> {
    const res = await getAssignmentsList(data)
    this.SET_ASSIGNMENTS_LIST(res)
  }

  @Action({ rawError: true })
  async getMediaList (): Promise<void> {
    const res = await getUploadFileByQuery({
      ...MediaLibraryModule.remoteEmp,
      page: MediaLibraryModule.remoteEmp.page - 1
    })
    await this.SET_MEDIA_LIST(res)
  }

  @Action({ rawError: true })
  async renameMediaFile (fileId: string): Promise<void> {
    const result = await JlinkUi.baseConfirm('', '修改文件名', {
      showInput: true,
      inputType: 'input',
      inputPlaceholder: '请输入文件名称'
    })
    if (result.action === 'confirm' && result.value) {
      const res = await renameUploadFile({ fileId, fileName: result.value })
      this.UPDATE_FILE({ fileId, fileName: res.fileName })
    }
  }

  @Action({ rawError: true })
  async downloadMediaFile (file: UploadFileVo) {
    await JlinkDownload.downloadOssFile(file)
  }

  @Action({ rawError: true })
  async deleteMediaFile (fileIds: string) {
    await deleteUploadFiles({ fileIds })
    const ids = fileIds.split(',')
    this.uploadFiles.removeIf('fileId', ids)
    this.checkedMedia.removeElement(ids)
  }

  @Action({ rawError: true })
  async deleteMediaFiles () {
    console.log(this.checkedMedia, 'checkedMedia')
    await this.deleteMediaFile(this.checkedMedia.join(','))
  }
}

export const MediaLibraryModule = getModule(MediaLibrary)
