import 'normalize.css'
import '@/common/base'
import '@/scss/element-ui.scss'
import '@/scss/app-ui.scss'
import 'element-plus/theme-chalk/index.css'
import { createApp } from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import errorInstall from '@/common/errors/error'
import { svgLoad } from '@/common/global/JlinkSourceLoad'
svgLoad()

const app = createApp(App)
  // set ElementUI lang to EN
  // 如果想要中文版 element-ui，按如下方式声明
  // Vue.use(ElementUI)
  .use(ElementPlus, { locale: zhCn })
  .use(store)
  .use(router)
errorInstall(app)
app.mount('#app', undefined, undefined)
