import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store/index'
import { djCloudLogin } from '@/api/api.user'
import JlinkStorage from '@/common/global/JlinkStorage'
@Module({ name: 'djiclouduser', dynamic: true, namespaced: true, store })
class DjiCloudUser extends VuexModule {
  data: {
    user?: LoginUserVo
  } = {
      user: JlinkStorage.get('loginUser'),
    }

  @Mutation
  private RESET_USER_DATA () {
    this.data = {}
    JlinkStorage.remove('loginUser')
  }

  @Mutation
  SAVE_USER_DATA (user: LoginUserVo | null) {
    if (user) {
      const merge = { ...this.data.user, ...user }
      JlinkStorage.set('loginUser', merge)
      this.data.user = merge
    } else {
      this.RESET_USER_DATA()
    }
  }

  @Action({ rawError: true })
  async loginDjiCloud (data: { loginName: string; loginPwd: string, control: string }): Promise<void> {
    this.RESET_USER_DATA()
    const result = await djCloudLogin(data)
    this.SAVE_USER_DATA(result)
  }

  @Action({ rawError: true })
  async logout (): Promise<void> {
    this.RESET_USER_DATA()
  }
}

export const DjiCloudUserModule = getModule(DjiCloudUser)
