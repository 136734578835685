export const COMMON_CLOUD_LOGIN = {
  path: '/cloudLogin',
  name: 'cloudLogin',
  fullPath: '/cloudLogin'
}
export const COMMON_WEB_LOGIN = {
  path: '/login',
  name: 'login',
  fullPath: 'login',
}

export const COMMON404 = {
  path: '/404',
  name: '404',
  fullPath: '/404'

}

export const COMMON_LIVESTREAM_SHARE = {
  path: '/liveShare',
  name: 'liveShare',
  fullPath: '/liveShare'
}

export const COMMON_LOCATION_SHARE = {
  path: '/locationShare',
  name: 'locationShare',
  fullPath: '/locationShare'
}
