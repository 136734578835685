import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  return _openBlock(), _createBlock($setup["DeepImage"], {
    "class": "deep-company-image",
    fit: "cover",
    src: (_a = $props.src) === null || _a === void 0 ? void 0 : _a.companyLogo,
    lazy: $props.lazy
  }, {
    "default": _withCtx(function () {
      return [_createVNode($setup["DeepSvgIcon"], {
        name: "jlink",
        colorFull: "",
        style: {
          "width": "100%",
          "height": "100%"
        }
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["src", "lazy"]);
}