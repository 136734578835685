import { RouteRecordRaw } from 'vue-router'
import {
  COMMON404,
  COMMON_CLOUD_LOGIN,
  COMMON_WEB_LOGIN
} from '@/router/route.model.base'

/* 公共路由 */
const baseRoutes: RouteRecordRaw[] = [
  {
    // 匹配所有路径  vue2使用*   vue3使用/:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
    path: '/:catchAll(.*)',
    redirect: COMMON404.path,
    name: 'catch',
    component: () => import('@/app/layout/EmptyLayout.vue'),
    meta: { title: '错误', hidden: true, keepAlive: false },
    children: []
  },
  {
    path: COMMON404.path,
    name: COMMON404.name,
    component: () => import('@/app/views/pages/404.vue'),
    meta: { title: '404', hidden: true, keepAlive: false }
  },
  {
    path: '/redirect/:path(.*)',
    name: 'redirect',
    component: () => import('@/app/views/pages/redirect/RedirectTo.vue'),
    meta: { title: '重定向', hidden: true, keepAlive: false }
  },
  {
    path: COMMON_CLOUD_LOGIN.path,
    component: () => import('@/app/views/pages/login/CloudLogin.vue'),
    name: COMMON_CLOUD_LOGIN.name,
    meta: { title: '第三方云', hidden: true, icon: 'link' }
  },
  {
    path: COMMON_WEB_LOGIN.path,
    name: COMMON_WEB_LOGIN.name,
    component: () => import('@/app/views/pages/login/WebLogin.vue'),
    meta: { title: '登录', hidden: true, keepAlive: false }
  }
]

export default baseRoutes
