import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store/index'
import { deleteFlightTask, getFlightTaskListByPage } from '@/api/api.flighttask'
import JlinkUtils from '@/common/global/JlinkUtils'

@Module({ name: 'waylineJobTaskList', dynamic: true, namespaced: true, store })
class WaylineJobTaskList extends VuexModule {
  tasks: FlightTaskVo[] = []
  totalCount = 0
  remoteEmp: ReqFlightTaskListByPage['Request'] = {
    jobId: '',
    page: 1,
    size: 20,
  }

  data:{job?:WaylineJobVo} = {}

  publishDate: string = ''

  @Mutation
  SAVE_PUBLISH_DATE (date: string) {
    this.publishDate = date
  }

  @Mutation
  SELECT_JOB (data?: WaylineJobVo) {
    this.remoteEmp.jobId = data?.jobId || ''
    this.data.job = data
  }

  @Mutation
  SET_JOB_TASK_LIST (data: ReqFlightTaskListByPage['Response']) {
    this.totalCount = data.totalCount
    this.tasks = data.data
  }

  @Mutation
  APPEND_TASK (task: FlightTaskVo) {
    if (this.remoteEmp.page === 1) {
      if (this.tasks.map(i => i.taskId).includes(task.taskId)) {
        return
      }
      const len = this.tasks.unshift(task)
      if (len > this.remoteEmp.size) {
        this.tasks.pop()
      }
    }
    this.totalCount += 1
  }

  @Mutation
  CHANGE_TASK (data: DockFlightTaskMediaCountUpdateResult) {
    const target = this.tasks.findBy('taskId', data.taskId)
    if (target) {
      JlinkUtils.obj.dataSet(data, target)
    }
  }

  @Action({ rawError: true })
  async getList (): Promise<void> {
    if (WaylineJobTaskListModule.remoteEmp.jobId) {
      const res = await getFlightTaskListByPage({
        ...WaylineJobTaskListModule.remoteEmp,
        page: WaylineJobTaskListModule.remoteEmp.page - 1
      })
      this.SET_JOB_TASK_LIST(res)
    } else {
      this.SET_JOB_TASK_LIST({ totalCount: 0, data: [] })
    }
  }

  @Action({ rawError: true })
  async deleteTask (data: ReqDeleteFlightTask['Request']): Promise<void> {
    await deleteFlightTask(data)
    await this.getList()
  }
}

export const WaylineJobTaskListModule = getModule(WaylineJobTaskList)
