import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/store/index'
import { changePwd, getAuthCode, login, loginByNPRKey, logout, miniprogramLoginByToken, modifyUser } from '@/api/api.user'
import { AppConfigModule } from '@/store/AppConfig'
import DataException from '@/common/errors/DataException'
import JlinkStorage from '@/common/global/JlinkStorage'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkTask from '@/common/global/JlinkTask'
import JlinkUi from '@/common/global/JlinkUi'
import JlinkMqtt from '@/common/global/JlinkMqtt'

@Module({ name: 'user', dynamic: true, namespaced: true, store })
class User extends VuexModule {
  user = JlinkStorage.get('loginUser') || {} as LoginUserVo
  authorities = this.user?.userAuthorities || []

  @Mutation
  RESET_USER_DATA () {
    this.user = {} as LoginUserVo
    this.authorities = []
    JlinkStorage.remove('loginUser')
  }

  @Mutation
  async SAVE_USER_DATA (user: LoginUserVo | null) {
    if (user) {
      JlinkUtils.obj.dataSet(user, this.user)
      this.authorities = this.user.userAuthorities
      JlinkStorage.set('loginUser', this.user)
    } else {
      await UserModule.RESET_USER_DATA()
    }
  }

  @Mutation
  SAVE_AUTHORITIES (auth: string[]) {
    if (this.user) {
      this.user.userAuthorities = auth
      this.authorities = auth
      JlinkStorage.set('loginUser', this.user)
    }
  }

  @Mutation
  UPDATE_LICENSE (license: FlyLicenseVo) {
    if (this.user) {
      this.user.userLicense = license
      JlinkStorage.set('loginUser', this.user)
    }
  }

  @Action({ rawError: true })
  async platformLogin (userInfo: string): Promise<void> {
    let user: LoginUserVo
    try {
      user = JSON.parse(userInfo.toString())
    } catch (e) {
      throw new DataException('userInfo can not parse as LoginUserVo')
    }
    await this.SAVE_USER_DATA(user)
    await AppConfigModule.getDetail()
  }

  @Action({ rawError: true })
  async platformDing (userInfo: any): Promise<void> {
    await this.SAVE_USER_DATA(userInfo)
    await AppConfigModule.getDetail()
  }

  @Action({ rawError: true })
  async loginWeb (data: { loginName: string; loginPwd: string; }): Promise<void> {
    this.RESET_USER_DATA()
    const result = await login({ loginName: data.loginName, loginPwd: data.loginPwd })
    await this.SAVE_USER_DATA(result)
    await AppConfigModule.getDetail()
  }

  @Action({ rawError: true })
  async miniprogramLoginByToken (token: string): Promise<void> {
    this.RESET_USER_DATA()
    const result = await miniprogramLoginByToken({ token })
    await this.SAVE_USER_DATA(result)
    await AppConfigModule.getDetail()
  }

  @Action({ rawError: true })
  async loginByNPRKey (nprKey: string): Promise<void> {
    this.RESET_USER_DATA()
    const result = await loginByNPRKey({ nprKey })
    await this.SAVE_USER_DATA(result)
    await AppConfigModule.getDetail()
  }

  @Action({ rawError: true })
  async logout (message: string): Promise<void> {
    const res = await JlinkUi.baseConfirm(`${message} 请点击确定重新登录` || '未知错误，请重新登录', '重新登录', { showCancelButton: true })
    if (res.action === 'confirm') {
      await logout()
      await JlinkTask.sleep(100)
      await JlinkMqtt.setOptions()
      JlinkStorage.remove('DeviceEnum')
      UserModule.RESET_USER_DATA()
      window.location.reload()
    }
  }

  @Action({ rawError: true })
  changePwd (data: ReqChangePwd['Request']): Promise<boolean> {
    return changePwd(data)
  }

  @Action({ rawError: true })
  getAuthCode (data: ReqGetAuthCode['Request']): Promise<boolean> {
    return getAuthCode(data)
  }

  @Action({ rawError: true })
  async modifyUser (data: ReqUserUpdate['Request']): Promise<void> {
    const res = await modifyUser(data)
    await this.SAVE_USER_DATA(res)
  }

  @Mutation
  UPDATE_LOCATION (location: { address: string; lng: number; lat: number }) {
    this.user.userLocation = location
  }
}

export const UserModule = getModule(User)
